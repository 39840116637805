import { Controller } from "stimulus"
import {
  startCase
} from "helpers";

const tags = ['firstName', 'lastName', 'street', 'city', 'state', 'zip', 'dob', 'signedDate', 'employeeIdentificationNumber']
const errorTags = tags.map(target => `${target}Error`)
const specialTags = ['cellPhone', 'cellPhoneError', 'ssn', 'ssnError']

export default class extends Controller {
  static targets = [...tags, ...errorTags, ...specialTags]
  
  initialize() {
    this.validateError()
    this.validateSsnError()
    this.validatePhoneError()
  }

  validateError() {
    tags.map((tag) => {
      if(this[`has${startCase(tag)}ErrorTarget`]) {
        const count = this[`${tag}Target`].value.length;
        let message = ''
        if(count <= 1) {
          message = 'Required*'
        }
      return this[`${tag}ErrorTarget`].textContent = message
      }
    })
  }

  validateSsnError() {
    const ssnFormat = /\(?\d{3}\)?[\s.-]?\d{2}[\s-.]?\d{4}/g;
    const number = this.ssnTarget.value;
    let message = ''
    if(!number.match(ssnFormat)) {
      message = 'Format eg. 123-46-7890'
    }
    return this.ssnErrorTarget.textContent = message
  }

  validatePhoneError() {
    const phoneFormat = /(\+\d{1})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s-.]?\d{4}/g;
    const number = this.cellPhoneTarget.value;
    let message = ''
    if(!number.match(phoneFormat)) {
      message = 'Format eg. 123-456-7890'
    }
    return this.cellPhoneErrorTarget.textContent = message
  }
}