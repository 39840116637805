
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "signatureField" ]

  initialize() {
    if (document.querySelector("canvas")) {
      this.canvas = document.querySelector("canvas");
      this.signaturePad = new SignaturePad(this.canvas);
      this.resizeCanvas();
    }
  }

  resizeCanvas() {
    let ratio =  Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  clearSignaturePad(e) {
    e.preventDefault();
    this.signaturePad.clear() 
  }

  submitSignaturePad(e) {
    if (this.signaturePad.isEmpty()){
      e.preventDefault();
      alert('You must sign to Sign the signature pad');
    } else {
      this.signatureFieldTarget.value = this.signaturePad.toDataURL();
    }
  }
}