import { Controller } from "stimulus"

const tags = ['links', 'template', 'checkBox']

export default class extends Controller {
  static targets = [...tags]
  
  initialize() {
  }

  addAssociation(e) {
    e.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  toggleAssociation(e) {
    if(this.checkBoxTarget.checked) {
      document.querySelectorAll(".nested-fields").forEach(e => e.parentNode.removeChild(e));
    } else {
      this.addAssociation(e)
    }
  }

  removeAssociation(e) {
    e.preventDefault()
    let wrapper = e.target.closest(".nested-fields")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }
 }