import CheckboxSelectAll from "stimulus-checkbox-select-all"
import Rails from '@rails/ujs';

export default class extends CheckboxSelectAll {

  message(event) {
    event.preventDefault()
    const message_id = this.checkboxAllTarget.getAttribute("data-params");
    let data = new FormData()
    if (this.checked.length == this.checkboxTargets.length) {
      data.append("all", true)
    } else {
      this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))
    }

    Rails.ajax({
      url: `/messages/${message_id}/message_departments`,
      type: "post",
      data: data
    })
  }
}
